import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CookingComponent} from './cooking/cooking.component';
import {HomeComponent} from './home/home.component';
import {TravelComponent} from './travel/travel.component';
import {BlogPostComponent} from './blog-post/blog-post.component';
import {ComingSoonComponent} from './coming-soon/coming-soon.component';


const routes: Routes = [
  { path: '', redirectTo: 'coming-soon', pathMatch: 'full' },
  { path: 'home',  component: HomeComponent },
  { path: 'cooking',  component: CookingComponent },
  { path: 'cooking/:slug', component: BlogPostComponent },
  { path: 'travel',  component: TravelComponent },
  { path: 'travel/:slug', component: BlogPostComponent },
  { path: 'coming-soon',  component: ComingSoonComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
