import { Injectable } from '@angular/core';
import { ContentfulClientApi, Entry, EntryCollection, createClient, Space } from 'contentful';

import { environment } from '../../environments/environment';
import { BlogPost } from './blog-post';
import { Category } from './category';
import { CookingSubcategory } from './cooking-subcategory';
import { TravelSubcategory } from './travel-subcategory';

@Injectable({
  providedIn: 'root',
})
export class ContentfulApiService { private clientApi: ContentfulClientApi;

  constructor() {
    this.clientApi = createClient({
      space: environment.contentful.space,
      accessToken: environment.contentful.accessToken,
    });
  }

  /**
   * Get a single blog post by its slug
   *
   * @param slug The slug for the blog post
   */
  getBlogPost(slug: string): Promise<Entry<BlogPost>> {
    return this.getBlogPosts({
      'fields.slug': slug,
    }).then((response) => response.items[0]);
  }

  /**
   * Get listing of all blog posts
   *
   * @param query Filter object
   */
  getBlogPosts(query?: object): Promise<EntryCollection<BlogPost>> {
    return this.clientApi.getEntries<BlogPost>(
      Object.assign({}, query, { content_type: 'blogPost' })
    );
  }

  /**
   * Get listing of Cooking blog posts
   *
   * @param query Filter object
   */
  getCookingBlogPosts(query?: object): Promise<EntryCollection<BlogPost>> {
    return this.clientApi.getEntries<BlogPost>(
      Object.assign({}, query, { content_type: 'blogPost', 'fields.categories.sys.id[in]': '5RwpvyOB7rrGg8VopXGD13' })
    );
  }

  /**
   * Get listing of Travel blog posts
   *
   * @param query Filter object
   */
  getTravelBlogPosts(query?: object): Promise<EntryCollection<BlogPost>> {
    return this.clientApi.getEntries<BlogPost>(
      Object.assign({}, query, { content_type: 'blogPost', 'fields.categories.sys.id[in]': '1PCLrb923RLaBUtzM6Aswg' })
    );
  }

  /**
   * Get listing of all categories
   *
   * @param query Filter object
   */
  getCategory(query?: object): Promise<EntryCollection<Category>> {
    return this.clientApi.getEntries<Category>(
      Object.assign({}, query, { content_type: 'categories'})
    );
  }

  /**
   * Get listing of all categories
   *
   * @param query Filter object
   */
  getcookingSubCategory(query?: object): Promise<EntryCollection<CookingSubcategory>> {
    return this.clientApi.getEntries<CookingSubcategory>(
      Object.assign({}, query, { content_type: 'cookingSubCategory'})
    );
  }

  /**
   * Get listing of all categories
   *
   * @param query Filter object
   */
  gettravelSubCategory(query?: object): Promise<EntryCollection<TravelSubcategory>> {
    return this.clientApi.getEntries<TravelSubcategory>(
      Object.assign({}, query, { content_type: 'travelSubCategory'})
    );
  }
}
