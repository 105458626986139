import { Component, OnInit } from '@angular/core';

import { ContentfulApiService } from '../contentful/contentful-api.service';
import { Entry } from 'contentful';
import {BlogPost} from '../contentful/blog-post';

@Component({
  selector: 'app-travel',
  templateUrl: './travel.component.html',
  styleUrls: ['./travel.component.scss']
})
export class TravelComponent implements OnInit {
  travelPosts: Array<Entry<BlogPost>>;

  constructor(private contentfulApiService: ContentfulApiService) { }

  ngOnInit(): void {
    this.contentfulApiService
      .getTravelBlogPosts()
      .then((travelPosts) => (this.travelPosts = travelPosts.items));
  }
}
