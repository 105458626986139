<ng-container *ngIf="blogPost; else loading">
  <ul>
    <li *ngFor="let cookingsubcategory of cookingsubCategory">
      <a [routerLink]="cookingsubcategory.fields.slug">{{ cookingsubcategory.fields.title }}</a>
    </li>
  </ul>
  <h1>{{ blogPost.fields.postTitle }}</h1>
  <img
    [src]="blogPost.fields.featuredImage.fields.file.url"
    [alt]="blogPost.fields.featuredImage.fields.title"
  />
  <!--ngx-contentful-rich-text [document]="blogPost.fields.content">
  </ngx-contentful-rich-text-->
</ng-container>
<ng-template #loading>Loading...</ng-template>
