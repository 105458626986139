<!--<nav class="navbar navbar-expand-lg navbar-light bg-white mr-5">-->
<!--  <a class="navbar-brand mr-5" href="#">-->
<!--    <img src="/assets/Delicious%20Bulgaria-logo.png" width="300" height="100" class="d-inline-block align-top" alt="" loading="lazy">-->
<!--  </a>-->
<!--  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">-->
<!--    <span class="navbar-toggler-icon"></span>-->
<!--  </button>-->

<!--  <div class="collapse navbar-collapse" id="navbarSupportedContent">-->
<!--    <ul class="navbar-nav mr-auto">-->
<!--      <li class="nav-item active">-->
<!--        <a class="nav-link" routerLink="/home">Home<span class="sr-only">(current)</span></a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/cooking">Cooking</a>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="/travel">Travel</a>-->
<!--      </li>-->
<!--    </ul>-->
<!--    <form class="form-inline my-2 my-lg-0">-->
<!--      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">-->
<!--      <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>-->
<!--    </form>-->
<!--  </div>-->
<!--</nav>-->
<!--<main class="mt-5">-->
<!--  <router-outlet></router-outlet>-->
<!--</main>-->
<router-outlet></router-outlet>
