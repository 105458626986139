<div class="coming-soon">
  <div class="container">
    <div class="col-12">
      <div class="d-flex flex-column">
        <h1 class="text-white mb-4">We are Coming soon.</h1>
        <p class="text-white">Delicious and easy to prepare recipes  for every food lover . Sign up to be the first to know when we launch.</p>
      </div>
    </div>
  </div>
</div>

