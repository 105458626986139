<div class="d-flex container">
  <div class="row">

    <div class="d-flex flex-column w-50 col-12 col-md-6 col-lg-3 p-0" *ngFor="let cookingPost of cookingPosts; let even = even" [class.even]="even">
      <div class="info-container px-2">
        <a class="post-subcategory px-3 py-1" [title]="cookingPost.fields.cookingSubCategory.fields.title" [routerLink]="cookingPost.fields.cookingSubCategory.fields.slug">{{ cookingPost.fields.cookingSubCategory.fields.title }}</a>
        <a [title]="cookingPost.fields.postTitle" [routerLink]="cookingPost.fields.slug">{{ cookingPost.fields.postTitle }}</a>
        <span>{{cookingPost.fields.publishedDate | date:'longDate'}}</span>
      </div>
      <a [routerLink]="cookingPost.fields.slug">
        <img [src]="cookingPost.fields.previewImage.fields.file.url"
             [alt]="cookingPost.fields.featuredImage.fields.title"/>
      </a>
    </div>
  </div>
</div>
