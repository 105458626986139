import { Component, OnInit } from '@angular/core';

import { ContentfulApiService } from '../contentful/contentful-api.service';
import { Entry } from 'contentful';
import {BlogPost} from '../contentful/blog-post';
import {Category} from '../contentful/category';

@Component({
  selector: 'app-cooking',
  templateUrl: './cooking.component.html',
  styleUrls: ['./cooking.component.scss']
})
export class CookingComponent implements OnInit {
  cookingPosts: Array<Entry<BlogPost>>;
  categories: Array<Entry<Category>>;
  constructor(private contentfulApiService: ContentfulApiService) { }

  ngOnInit(): void {
    this.contentfulApiService
      .getCookingBlogPosts()
      .then((cookingPosts) => (this.cookingPosts = cookingPosts.items));
  }
}
