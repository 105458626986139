<div class="d-flex container">
  <div class="row">

    <div class="d-flex flex-column w-50 col-12 col-md-6 col-lg-3 p-0" *ngFor="let travelPost of travelPosts; let even = even" [class.even]="even">
      <div class="info-container px-2">
        <a class="post-subcategory px-3 py-1" [title]="travelPost.fields.travelSubCategory.fields.name" [routerLink]="travelPost.fields.travelSubCategory.fields.slug">{{ travelPost.fields.travelSubCategory.fields.name }}</a>
        <a [title]="travelPost.fields.postTitle" [routerLink]="travelPost.fields.slug">{{ travelPost.fields.postTitle }}</a>
        <span>{{travelPost.fields.publishedDate | date:'longDate'}}</span>
      </div>
      <a [routerLink]="travelPost.fields.slug">
        <img [src]="travelPost.fields.previewImage.fields.file.url"
             [alt]="travelPost.fields.featuredImage.fields.title"/>
      </a>
    </div>
  </div>
</div>
